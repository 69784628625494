'use client'
import React, { use, useState } from 'react'
import Heading from '@/components/common/heading/Heading'
import { HeadingType } from '../../types/mixed.types'
import { Button, Col, Form, Input, Modal, Row } from 'antd'
import Link from 'next/link'
import authService from '@/services/auth.service'
import { useLocale, useTranslations } from 'next-intl'
import { useUserState } from '@/store/user.store'
import { jwtDecode } from 'jwt-decode'
import { useRouter } from 'next/navigation'
import styles from './login.module.scss'

type FieldType = {
    email?: string
    password?: string
    remember?: string
}

const LoginTouristContainer = () => {
    const [loading, setLoading] = useState(false)
    const userState = useUserState()
    const locale = useLocale()
    const t = useTranslations('loginPages')
    const valM = useTranslations('loginPages.validations')
    const redirect = useRouter()
    if (userState.accessToken) redirect.replace(`/${locale}`)
    const onFinish = async (values: any) => {
        try {
            setLoading(true)
            const res = await authService.login(values)
            if (res) {
                const decoded: any = jwtDecode(res.data)
                userState.setRole(decoded.role)
                userState.setUniqueName(decoded.unique_name)
                userState.setEmail(decoded.email)
                userState.setAccessToken(res.data)
                redirect.replace(`/${locale}`)
            }
        } catch (error) {
            if (error instanceof Error) {
                Modal.error({
                    title: 'Ошибка авторизоваться',
                    content: error.message,
                })
            }
        } finally {
            setLoading(false)
        }
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo)
    }

    return (
        <section className={styles.register}>
            <Heading type={HeadingType.h2} text={t('login')} />
            <div className={styles.buttons}>
                <Row justify={'center'}>
                    <Col lg={6} xs={20}>
                        <Form
                            name="basic"
                            layout="vertical"
                            style={{ width: '100%' }}
                            initialValues={{ remember: true }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >
                            <Form.Item<FieldType>
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: valM('email'),
                                    },
                                ]}
                            >
                                <Input
                                    placeholder={t('email')}
                                    className={styles.btn}
                                />
                            </Form.Item>
                            <Form.Item<FieldType>
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: valM('password'),
                                    },
                                ]}
                            >
                                <Input.Password
                                    placeholder={t('password')}
                                    className={styles.btn}
                                />
                            </Form.Item>

                            <Form.Item>
                                <Button
                                    loading={loading}
                                    htmlType="submit"
                                    className={styles.btn2}
                                >
                                    {t('login')}
                                </Button>
                            </Form.Item>
                        </Form>
                        <div className={styles.bottom_text}>
                            {t('noAccount')}{' '}
                            <Link href={`/${locale}/register`}>
                                {t('register')}
                            </Link>
                        </div>
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default LoginTouristContainer
