import { API_BASE_URL } from '@/config/AppConfig'

const authService = {
    async partnerRegister(user: PartnerCreateType): Promise<any> {
        const res = await fetch(`${API_BASE_URL}/auth/partner-register`, {
            method: 'POST',
            body: JSON.stringify(user),
            headers: {
                'Content-Type': 'application/json',
            },
        })
        if (!res.ok) {
            const errorRes = await res.text();
            throw new Error(errorRes || 'Registration failed');
        }
        return true
    },
    async createTourist(user: TouristCreateType): Promise<any> {
        try {
            const res = await fetch(`${API_BASE_URL}/auth/tourist-register`, {
                method: 'POST',
                body: JSON.stringify(user),
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (!res.ok) {
                const errorRes = await res.text();
                throw new Error(errorRes || 'Registration failed');
            }
            return true;
        } catch (error) {
            throw error;
        }
    },
    async login(user: LoginType): Promise<any> {
        try {
            const res = await fetch(`${API_BASE_URL}/auth/login`, {
                method: 'POST',
                body: JSON.stringify(user),
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            if (!res.ok) {
                const errorRes = await res.text();
                throw new Error(errorRes || 'Login failed');
            }
            return await res.json();
        } catch (error) {
            throw error
        }
    },
}
export default authService
